import * as React from "react";
import {useEffect, useState} from "react";
import {createTagString} from "../../../services/tagsHelper";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {connect} from "react-redux";

const ContactTags = ({
     contactId, contactTags, setContactTags,
     contactTempTags, setContactTempTags, dispatch
}) => {

    const
        [ uiBlockClasses, setUiBlockClasses] = useState('CD_PrivateTag_Container'),
        [ editBlockClasses, setEditBlockClasses] = useState('CD_PrivateTag_Editor position-relative d-none'),
        [ plainTags, setPlainTags] = useState(createTagString(contactTags)),
        [ newTag, setNewTag ] = useState(''),
        [ addTag, setAddTag ] = useState(false),
        [ updateTags, setUpdateTags ] = useState(false),
        [ restoreTags, setRestoreTags ] = useState(false),
        [ removeTagIndex, setRemoveTagIndex ] = useState(null)
    ;

    const showEditTagBlock = () => {
        setUiBlockClasses('CD_PrivateTag_Container d-none');
        setEditBlockClasses('CD_PrivateTag_Editor position-relative');
    }

    const hideEditTagBlock = () => {
        setUiBlockClasses('CD_PrivateTag_Container');
        setEditBlockClasses('CD_PrivateTag_Editor position-relative d-none');
    }

    const saveTags = () => {
        if(contactId) {
            notificationProcessing(dispatch);

            return new Promise(async () => {
                await Axios.post(
                    `${process.env.GATSBY_BACKEND_API_URL}/contact/update-tags/${contactId}`,
                    {
                        tags: contactTempTags
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken()
                        }
                    }
                ).then(function (response) {
                    setUpdateTags(true)

                    hideEditTagBlock()
                    notificationSuccess(dispatch);
                }).catch(function (error) {
                    notificationFail(dispatch);
                });
            });
        }
    }

    useEffect( () => {
        if(addTag) {
            setAddTag(false)
            setContactTempTags([
                ...contactTempTags,
                {
                    tag: newTag,
                    score: 0,
                }
            ])
            setNewTag('');
        }

        if(removeTagIndex) {
            setRemoveTagIndex(null);
            let tempTag = [];

            contactTempTags.map((tagArray, arrayIndex) => {
                if(removeTagIndex !== arrayIndex) {
                    tempTag = [...tempTag, tagArray];
                }
                if(arrayIndex === ( contactTempTags.length - 1 )) {
                    setContactTempTags([...tempTag]);
                }
            })
        }

        if(updateTags) {
            setUpdateTags(false);
            setContactTags([...contactTempTags]);
        }

        if(restoreTags) {
            setRestoreTags(false);
            setContactTempTags([...contactTags]);
        }
    })

    return (
        <React.Fragment>
            <div className={uiBlockClasses}>
                <div className="SwiftCloudTableTags">
                    <ul>
                        { contactTempTags.length && contactTempTags.map( (tagDetail) => (
                            <li key={tagDetail.tag}>
                                <a href="#" data-tagname="Designer">{tagDetail.tag}</a>
                            </li>
                        ))}
                    </ul>

                    <a onClick={showEditTagBlock} className="btn btnEditPrivateTags" data-bs-toggle="tooltip" data-placement="top" title="Edit Tags">
                        <i className="bi bi-pencil-square" />
                    </a>
                </div>
            </div>
            <div className={editBlockClasses}>
                <div className="bootstrap-tagsinput">
                    { contactTempTags.length && contactTempTags.map( (tagDetail, tagIndex) => (
                        <span className="tag label label-info" key={tagDetail.tag}>
                            {tagDetail.tag}
                            <span data-role="remove" onClick={() => setRemoveTagIndex(tagIndex)} />
                            <span data-role="drag" />
                        </span>
                    ))}
                    <input
                        type="text"
                        placeholder=""
                        onKeyDown={(e) => {
                            if(e.code === 'Tab') {
                                setAddTag(true)
                            }
                        }}
                        value={newTag}
                        defaultValue={newTag}
                        onChange={ (e) => {
                            setNewTag(e.target.value);
                        }}
                    />
                    <button className="btn btn-sm btn-primary btnSaveTag">
                        <i className="bi bi-save-fill" />
                    </button>
                </div>
                <input type="text" className="form-control d-none" defaultValue={plainTags} />
                <button
                    type="button"
                    className="btnSaveGroupTags"
                    data-bs-toggle="tooltip"
                    title="Save Tags"
                    onClick={saveTags}
                >
                    <i className="bi bi-save-fill" />
                </button>
                <button
                    type="button"
                    className="btnAddRoleTags"
                    data-bs-toggle="tooltip"
                    title="cancel"
                    onClick={() => {
                        setRestoreTags(true)
                        hideEditTagBlock()
                    }}
                >
                    <i className="bi bi-x-lg" />
                </button>
            </div>
        </React.Fragment>
    )
}

export default connect(state => {
    return { ...state }
})(ContactTags)