import * as React from "react";

const ContactCompanyName = ({ contactId, contactCompanyName, setContactCompanyName }) => {

    return (
        <React.Fragment>
                { contactCompanyName !== null && <div className="CD_Info_Row border-bottom p-3">
                    <div className="row align-items-center">
                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Company Name</div>
                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                            <span data-bs-toggle="tooltip" data-placement="top" title="Private Information">
                                <i className="bi bi-building privateInfo" />
                            </span>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <a href="#">
                                {contactCompanyName}
                            </a>
                        </div>
                        <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                            <a href="#" className="btnCDDelete" data-bs-toggle="tooltip" data-placement="top" title="Delete">
                                <i className="bi bi-trash-fill" />
                            </a>
                        </div>
                    </div>
                </div>
                }

        </React.Fragment>
    )
}

export default ContactCompanyName;