import * as React from "react";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    CONTACT_ADDRESS_OPTIONS,
    getTypeData,
    PRIVACY_PUBLIC
} from "../../../services/globalStaticData";

const ContactAddresses = ({ contactId, contactAddresses, setContactAddresses, getContactData, dispatch }) => {

    const [updateContact, setUpdateContact] = useState(false);

    const deleteAddress = (addressId) => {
        if(addressId) {
            notificationProcessing(dispatch);

            return new Promise(async () => {
                await Axios.delete(
                    `${process.env.GATSBY_BACKEND_API_URL}/contact/address/delete/${addressId}` ,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken()
                        }
                    }
                ).then(function (response) {
                    if(response.status === 200) {
                        notificationSuccess(dispatch);
                        setUpdateContact(true)
                    }
                }).catch(function (error) {
                    if(error.response.status === 400) {
                        // setNotesTextAreaError(error.response.data.message)
                    } else {
                        // setNotesTextAreaError(error.response.data.detail)
                    }

                    notificationFail(dispatch);
                });
            });
        }
    }

    useEffect( () => {
        if(updateContact) {
            setUpdateContact(false);
            getContactData(contactId)
        }
    })
    return (
        <React.Fragment>

                { contactAddresses !== null && contactAddresses.length > 0 && contactAddresses.map( (addressData) => {
                    let addressTypeData = getTypeData(CONTACT_ADDRESS_OPTIONS, addressData.type);
                    let iconClasses = addressTypeData ? addressData.permission === PRIVACY_PUBLIC ? addressTypeData.icon : addressTypeData.icon + " privateInfo" : "";
                    return (
                        <React.Fragment key={`contact_detail_address_${addressData.id}`}>
                            {
                                addressTypeData && addressData.address &&
                                <div className="CD_Info_Row border-bottom p-3">
                                    <div className="row align-items-center">
                                        <div
                                            className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{addressData.type}</div>
                                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Private Information">
                                        <i className={iconClasses}/>
                                    </span>
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <a href="#" className=" btnOpenChatDock">{addressData.address}</a>&nbsp;
                                            <div className="showOnHover d-inline-block">
                                                <a href="#" className="btn btn-secondary btnQuickAction"
                                                   data-bs-toggle="tooltip" data-placement="top" aria-label="Mail">
                                                    <i className="bi bi-envelope-fill"/>
                                                </a>&nbsp;
                                                <a href="#" className="btn btn-secondary btnQuickAction"
                                                   data-bs-toggle="tooltip" data-placement="top" aria-label="Visit">
                                                    <i className="bi bi-geo-alt-fill"/>
                                                </a>&nbsp;
                                            </div>
                                        </div>
                                        <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                                            <a className="btnCDEdit" data-bs-toggle="tooltip" data-placement="top"
                                               title="Edit">
                                                <i className="bi bi-pencil-fill"/>
                                            </a>
                                            <a className="btnCDDelete" data-bs-toggle="tooltip" data-placement="top"
                                               title="Delete"
                                               onClick={() => deleteAddress(addressData.id)}
                                            >
                                                <i className="bi bi-trash-fill"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    )})
                }

        </React.Fragment>
    )
}

export default connect(state => {
    return { ...state }
})(ContactAddresses)