import * as React from "react";
import {
    CONTACT_EMAIL_OPTIONS,
    getTypeData,
    PRIVACY_PUBLIC
} from "../../../services/globalStaticData";

const ContactEmails = ({ contactId, contactEmails, setContactEmails }) => {

    return (
        <React.Fragment>

            { contactEmails !== null && contactEmails.length > 0 && contactEmails.map( (emailData) => {
                let emailTypeData = getTypeData(CONTACT_EMAIL_OPTIONS, emailData.type);
                let iconClasses = emailTypeData ? emailData.permission === PRIVACY_PUBLIC ? emailTypeData.icon : emailTypeData.icon + " privateInfo" : "";
                return (
                    <React.Fragment key={`contact_detail_emails_${emailData.id}`}>
                        {
                            emailTypeData && emailData.email &&
                            <div className="CD_Info_Row border-bottom p-3">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
                                        {emailData.type}
                                    </div>
                                    <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                                            <span data-bs-toggle="tooltip" data-placement="top" title="Public Information">
                                                <i className={iconClasses} />
                                            </span>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        <a className="email_deliverable btnOpenChatDock" data-bs-toggle="tooltip" title={`${emailData.email} - deliverable`}>
                                            {emailData.email} <i className="bi bi-check-lg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )})
            }

        </React.Fragment>
    )
}

export default ContactEmails;