import * as React from "react"
import Layout from "../../Layout/layout";
import {Link} from "gatsby";
import TimeAgo from "timeago-react";
import _uniqueId from 'lodash/uniqueId';
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {useEffect, useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import ContactTags from "./contactTags";
import ContactEmails from "./contactEmails";
import ContactPhones from "./contactPhones";
import ContactCompanyName from "./contactCompanyName";
import ContactHistory from "./contactHistory";

import '../../../css/chat-dock.css'
import '../../../css/bootstrap-tagsinput.css'
import '../../../css/bootstrap-utilities.css'
import './contact_detail.css'
import {historyBlockShow} from "../../../redux/reducers/contact/historyNotesDisplay";
import {connect} from "react-redux";
import AddDetailRow from "./AddDetailRow";
import ContactAddresses from "./contactAddresses";
import ContactSocialLinks from "./contactSocialLinks";
import ContactName from "./contactName";

const ContactDetail = ({ selectedContactId, dispatch }) => {
    const getContactData = async (selectedContactId) => {
        await Axios.get(
            `${process.env.GATSBY_BACKEND_API_URL}/contact/detail/${selectedContactId}`, {
                headers: {
                    'Authorization': 'Bearer ' + getAccessToken()
                }
            }
        ).then( (response) => {
            fillContactData(response.data.contact)
        });
    };

    const
        [contact, setContact] = useState(null),
        [contactError, setContactError] = useState(false),
        [loadContactDetails, setLoadContactDetails] = useState(true)
    ;

    const
        [contactName, setContactName] = useState(''),
        [tempContactName, setTempContactName] = useState(''),
        [contactCompanyName, setContactCompanyName] = useState(''),
        [contactJobTitle, setContactJobTitle] = useState(''),
        [contactImage, setContactImage] = useState(''),
        [contactTags, setContactTags] = useState([]),
        [contactTempTags, setContactTempTags] = useState([]),
        [contactEmails, setContactEmails] = useState([]),
        [contactPhones, setContactPhones] = useState([]),
        [contactAddresses, setContactAddresses] = useState([]),
        [contactSocialLinks, setContactSocialLinks] = useState([]),
        [contactHistoryNotes, setContactHistoryNotes] = useState([]),
        [dataRows, setDataRows] = useState([]);
    ;

    const fillContactData = (contactData) => {
        setContact(contactData);
        setContactName(contactData.name)
        setTempContactName(contactData.name)
        setContactImage('image url')
        setContactTags(contactData.tags)
        setContactTempTags(contactData.tags)
        setContactCompanyName(contactData.companyName)
        setContactJobTitle(contactData.jobTitle)
        setContactEmails(contactData.emails)
        setContactPhones(contactData.phones)
        setContactAddresses(contactData.addresses)
        setContactSocialLinks(contactData.socialLinks)
        setContactHistoryNotes(contactData.historyNotes)
    }

    const addNewDataRow = () => {
        setDataRows([ ...dataRows, _uniqueId("contact_add_data_row_")])
    }

    const removeNewDataRow = (removeIndex) => {
        let tempDataRows = [];

        dataRows.map((dataRow, dataRowIndex) => {
            if(removeIndex !== dataRowIndex) {
                tempDataRows = [...tempDataRows, dataRow];
            }
            if(dataRowIndex === ( dataRows.length - 1 )) {
                setDataRows([...tempDataRows]);
            }
        })

    }

    useEffect(() => {
        if(loadContactDetails) {
            setLoadContactDetails(false)
            getContactData(selectedContactId)
        }
    })

    const updateHistoryBlockOpen = () => {
        dispatch(historyBlockShow())
    }

  return (
      <Layout>
          <section className="mainSection" id="contactDetailTemplate">
              <div className="container-fluid">
                  { contact && <div className="row g-0">
                      <div className="col" id="page-urls">
                          <div className="row">

                              <div className="col-lg-6 col-md-12 contactDetailLeftSection">
                                  <div className="row g-0">
                                      <div className="col-sm-9 mb-3">
                                          <div className="pageTitle_Editor">
                                              <Link to="/contacts" className="goBack">
                                                  <i className="bi bi-arrow-90deg-left" />
                                              </Link>
                                              <span className="CD_UserType" data-bs-toggle="tooltip" title="Human">
                                                  <input type="checkbox" id="CD_UserType" />
                                                  <label htmlFor="CD_UserType" className="checkmark" />
                                              </span>
                                              <ContactName
                                                  contactId={contact.id}
                                                  contactName={contactName}
                                                  setContactName={setContactName}
                                                  tempContactName={tempContactName}
                                                  setTempContactName={setTempContactName}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row g-0">
                                      <div className="col-lg-12 mb-3 contactDetailsContainer">
                                          <div className="row g-0">
                                              <div className="col p-3 position-relative">
                                                  <div className="CD_Avatar"
                                                       style={{ background: "url("+process.env.GATSBY_WRANGLER_URL+"StaticImages/bachchan-amitabh-image.jpg) no-repeat center / cover" }}
                                                  >

                                                      <img src={process.env.GATSBY_WRANGLER_URL + 'StaticImages/bachchan-amitabh-image.jpg'} className="invisible img-fluid"/>

                                                      <a href="#" className="btn btn-default btnEditAvatar" data-bs-toggle="tooltip" data-placement="top" title="Update Image">
                                                          <i className="bi bi-pencil-fill" />
                                                      </a>
                                                  </div>
                                                  <div className="CD_Info_Row position-relative w-75">

                                                      <ContactTags
                                                          contactId={contact.id}
                                                          contactTags={contactTags}
                                                          setContactTags={setContactTags}
                                                          contactTempTags={contactTempTags}
                                                          setContactTempTags={setContactTempTags}
                                                      />

                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row g-0">
                                              <div className="col border-top">

                                                  <ContactCompanyName
                                                      contactId={contact.id}
                                                      contactCompanyName={contactCompanyName}
                                                      setContactCompanyName={setContactCompanyName}
                                                  />

                                                  <ContactEmails
                                                      contactId={contact.id}
                                                      contactEmails={contactEmails}
                                                      setContactEmails={setContactEmails}
                                                  />

                                                  <ContactPhones
                                                      contactId={contact.id}
                                                      contactPhones={contactPhones}
                                                      setContactPhones={setContactPhones}
                                                      getContactData={getContactData}
                                                  />

                                                  <ContactAddresses
                                                      contactId={contact.id}
                                                      contactAddresses={contactAddresses}
                                                      setContactAddresses={setContactAddresses}
                                                      getContactData={getContactData}
                                                  />

                                                  <ContactSocialLinks
                                                      contactId={contact.id}
                                                      contactSocialLinks={contactSocialLinks}
                                                      setContactSocialLinks={setContactSocialLinks}
                                                      getContactData={getContactData}
                                                  />



                                                  { dataRows.length > 0 && dataRows.map((dataRow, dataRowIndex) => (
                                                      <AddDetailRow
                                                          contact={contact}
                                                          removeNewDataRow={removeNewDataRow}
                                                          getContactData={getContactData}
                                                          key={dataRow}
                                                          dataRowIndex={dataRowIndex}
                                                      />
                                                  ))}

                                                  <div className="CD_Info_Row CD_Info_AddNew_Row  p-3">
                                                      <div className="row">
                                                          <div className="col-sm-12 text-center">
                                                              <a className="btnAddNewGreenDashed btnAddNewContactDetail" data-bs-toggle="tooltip" title="Add New"
                                                                 onClick={addNewDataRow}
                                                              >
                                                                  <i className="bi bi-plus-lg" />
                                                              </a>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-lg-12 mb-3" id="calendar_panel">
                                          <div className="accordion-item panel panel-pink">
                                              <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#calendar_container" aria-expanded="false" aria-controls="calendar_container">
                                                  <i className="fa-regular fa-calendar-check me-2" /> Calendar
                                              </div>
                                              <div id="calendar_container" className="accordion-collapse collapse" aria-labelledby="calendar_container">
                                                  <div className="accordion-body">
                                                      <div className="row g-0">
                                                          <div className="col tableContainer">
                                                              <div className="row">
                                                                  <div className="col-lg-12">
                                                                      <div className="float-end">
                                                                          <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                                                                              <li className="nav-item">
                                                                                  <button type="button" className="btn btn-primary mt-1 me-1">
                                                                                      <i className="bi bi-plus-lg" /> Add New
                                                                                  </button>
                                                                              </li>
                                                                              contactDetailLeftSection                           <li className="nav-item" role="presentation">
                                                                                  <a className="nav-link active" id="upcoming-event-tab" data-bs-toggle="tab" data-bs-target="#upcoming-event-tab-pane" role="tab" aria-controls="upcoming-event-tab-pane" aria-selected="false"><span data-bs-toggle="tooltip" title="Upcoming Events"><i className="bi bi-clock-history fa-flip-horizontal"></i></span></a>
                                                                              </li>
                                                                              <li className="nav-item" role="presentation">
                                                                                  <a className="nav-link" id="past-event-tab" data-bs-toggle="tab" data-bs-target="#past-event-tab-pane" role="tab" aria-controls="past-event-tab-pane" aria-selected="false"><span data-bs-toggle="tooltip" title="Past Events"><i className="bi bi-clock-history"></i></span></a>
                                                                              </li>
                                                                          </ul>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-lg-12 tableFadeOut">
                                                                      <div className="tab-content autoAdjustSearchBox" id="CalendarTabs">
                                                                          <div className="tab-pane fade show active" id="upcoming-event-tab-pane" role="tabpanel" aria-labelledby="upcoming-event-tab" tabIndex="0">
                                                                              <table id="tblUpcomingEvents" className="table dataTable" cellSpacing="0" width="100%">
                                                                                  <thead>
                                                                                  <tr>
                                                                                      <th width="70%" className="nowrap">Event</th>
                                                                                      <th width="30%" className="d-none d-md-table-cell">Scheduled</th>
                                                                                  </tr>
                                                                                  </thead>
                                                                                  <tbody>
                                                                                  <tr>
                                                                                      <td>Event 1</td>
                                                                                      <td className="d-none d-md-table-cell">
                                                                                          <TimeAgo datetime="2022-12-25" />
                                                                                      </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                      <td>Event 2</td>
                                                                                      <td className="d-none d-md-table-cell">
                                                                                          <TimeAgo datetime="2022-12-31" />
                                                                                      </td>
                                                                                  </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </div>
                                                                          <div className="tab-pane fade" id="past-event-tab-pane" role="tabpanel" aria-labelledby="past-event-tab" tabIndex="0">
                                                                              <table id="tblPastEvents" className="table dataTable" cellSpacing="0" width="100%">
                                                                                  <thead>
                                                                                  <tr>
                                                                                      <th width="70%" className="nowrap">Event</th>
                                                                                      <th width="30%" className="d-none d-md-table-cell">Scheduled</th>
                                                                                  </tr>
                                                                                  </thead>
                                                                                  <tbody>
                                                                                  <tr>
                                                                                      <td>Event 1</td>
                                                                                      <td className="d-none d-md-table-cell">
                                                                                          <TimeAgo datetime="2022-10-24" />
                                                                                      </td>
                                                                                  </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-lg-12 mb-3" id="files_panel">
                                          <div className="accordion-item panel panel-beige">
                                              <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#files_container" aria-expanded="false" aria-controls="files_container">
                                                  <i className="fa-solid fa-paperclip me-2" /> Files
                                              </div>
                                              <div id="files_container" className="accordion-collapse collapse" aria-labelledby="files_container">
                                                  <div className="accordion-body">
                                                      <div className="row">
                                                          <div className="col-lg-12">
                                                              <div className="float-end">
                                                                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                                                                      <li className="nav-item">
                                                                          <button type="button" className="btn btn-secondary btnUpload">
                                                                              <i className="fa-solid fa-cloud-arrow-up" />
                                                                              <span className="d-none d-md-inline">Upload</span>
                                                                          </button>
                                                                      </li>
                                                                      <li className="nav-item" role="presentation">
                                                                          <a className="nav-link active" id="drive-tab" data-bs-toggle="tab" data-bs-target="#drive" role="tab" aria-controls="drive" aria-selected="false" data-toggle="tooltip" data-placement="top" title="Drive">
                                                                              <i className="bi bi-bar-chart" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item" role="presentation">
                                                                          <a className="nav-link" id="small-icon-tab" data-bs-toggle="tab" data-bs-target="#small-icon" role="tab" aria-controls="small-icon" aria-selected="false" data-toggle="tooltip" data-placement="top" title="Small Icons">
                                                                              <i className="fa-solid fa-table-cells-large" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item" role="presentation">
                                                                          <a className="nav-link" id="large-icon-tab" data-bs-toggle="tab" data-bs-target="#large-icon" role="tab" aria-controls="large-icon" aria-selected="false" data-toggle="tooltip" data-placement="top" title="Large Icons">
                                                                              <i className="bi bi-stop-fill" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item nav-item-trash" role="presentation">
                                                                          <a className="nav-link" href="#" data-toggle="tooltip" data-placement="top" title="View Templates">
                                                                              <i className="fa-regular fa-clone" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item nav-item-trash" role="presentation">
                                                                          <a className="nav-link" href="#" data-toggle="tooltip" data-placement="top" title="Trash">
                                                                              <i className="bi bi-trash-fill" />
                                                                          </a>
                                                                      </li>
                                                                  </ul>

                                                                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                                                                      <li className="nav-item">
                                                                          <button type="button" className="btn btn-secondary btnUpload me-1">
                                                                              <i className="fa-solid fa-cloud-arrow-up" />
                                                                          </button>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <div className="btn-group btnDriveView" role="group">
                                                                              <button id="btnGroupDrop3" type="button" className="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                  <i className="bi bi-bar-chart" />
                                                                              </button>
                                                                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                                                                                  <li>
                                                                                      <a className="dropdown-item" id="drive-tab" data-bs-toggle="tab" data-bs-target="#drive" role="tab" aria-controls="drive" aria-selected="false">
                                                                                          <i className="bi bi-bar-chart" /> Drive
                                                                                      </a>
                                                                                  </li>
                                                                                  <li>
                                                                                      <a className="dropdown-item" id="small-icon-tab" data-bs-toggle="tab" data-bs-target="#small-icon" role="tab" aria-controls="small-icon" aria-selected="false">
                                                                                          <i className="fa-solid fa-table-cells-large" /> Small Icons
                                                                                      </a>
                                                                                  </li>
                                                                                  <li>
                                                                                      <a className="dropdown-item" id="large-icon-tab" data-bs-toggle="tab" data-bs-target="#large-icon" role="tab" aria-controls="large-icon" aria-selected="false">
                                                                                          <i className="bi bi-stop-fill" /> Large Icons
                                                                                      </a>
                                                                                  </li>
                                                                                  <li>
                                                                                      <a className="dropdown-item" href="#">
                                                                                            <i className="fa-regular fa-clone" /> Templates
                                                                                      </a>
                                                                                  </li>
                                                                                  <li>
                                                                                      <a className="dropdown-item" href="#">
                                                                                          <i className="bi bi-trash-fill" /> Trash
                                                                                      </a>
                                                                                  </li>
                                                                              </ul>
                                                                          </div>
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                          <div className="col-lg-12">
                                                              <div className="tab-content autoAdjustSearchBox" id="myTabContent">
                                                                  <div className="tab-pane fade show active" id="drive" role="tabpanel" aria-labelledby="drive-tab">
                                                                      <table id="tblDrive" className="table dataTable" cellSpacing="0" width="100%">
                                                                          <thead>
                                                                          <tr>
                                                                              <th width="5%">&nbsp;</th>
                                                                              <th width="70%">
                                                                                  Name/Label
                                                                                  <button type="button" className="btn btn-sm btnTHSoryByTags float-end me-3" data-bs-toggle="modal" data-bs-target="#ContactsSortByTagModal" title="Advanced Tag Search">
                                                                                      <span data-toggle="tooltip" title="Advanced Tag Search">
                                                                                          <i className="bi bi-tag-fill" />
                                                                                      </span>
                                                                                  </button>
                                                                              </th>
                                                                              <th width="18%" className="d-none d-md-table-cell">Last Activity</th>
                                                                              <th width="7%" className="text-end d-none d-lg-table-cell"></th>
                                                                          </tr>
                                                                          <tr className="d-none table_action_container">
                                                                              <td colSpan="4" className="text-left">
                                                                                  <div className="batch-action-container">
                                                                                      <button className="btn btn-trash my-1">
                                                                                          <i className="bi bi-trash-fill" /> Move to Trash
                                                                                      </button>
                                                                                      <button className="btn btn-default my-1" data-toggle="tooltip" data-placement="top" title="Archive (Searchable but Hidden)">
                                                                                          <i className="bi bi-archive-fill" /> Archive
                                                                                      </button>
                                                                                      <button className="btn btn-secondary my-1">
                                                                                          <i className="bi bi-tag-fills" /> Apply Tag(s)
                                                                                      </button>
                                                                                      <button className="btn btn-success my-1">
                                                                                          <i className="bi bi-person-fill-plus" /> Manage Sharing
                                                                                      </button>
                                                                                      <button className="btn btn-primary my-1">
                                                                                          <i className="bi bi-unlock-fill" /> Manage Permissions
                                                                                      </button>
                                                                                      <button className="btn btn-secondary my-1">
                                                                                          <i className="bi bi-arrows-move" /> Move To...
                                                                                      </button>
                                                                                  </div>
                                                                              </td>
                                                                          </tr>
                                                                          </thead>
                                                                          <tbody>
                                                                          <tr className="folderRow">
                                                                              <td className="tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID1" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID1" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-folder">
                                                                                          <i className="fa-solid fa-folder" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Incoming</a>
                                                                                  <div className="SwiftCloudTableTags">
                                                                                      <ul>
                                                                                          <li><a href="#" data-tagname="Tag1">Tag1</a></li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-01" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr className="folderRow">
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID2" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID2" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan btn-folder">
                                                                                          <i className="fa-solid fa-folder" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Tasks and Projects</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-01" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID14" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID14" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan archiveIconBg">
                                                                                          <i className="fa-regular fa-file-zipper" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="file_zip.html">Abc.zip</a>
                                                                                  <div className="SwiftCloudTableTags">
                                                                                      <ul>
                                                                                          <li><a href="#" data-tagname="Zip">Zip</a></li>
                                                                                          <li><a href="#" data-tagname="Tag1">Tag1</a></li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <div className="SwiftCloudSharedWithUsers">
                                                                                      <ul>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="SwiftCloud">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/CloudFlag_SolidBG_Color2760a8_margin.gif"
                                                                                                      alt="CloudFlag_SolidBG_Color2760a8_margin.gif"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Rover V.">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/roger.jpg"
                                                                                                      alt="roger.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Amitabh Bachchan">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/bachchan-amitabh-image.jpg"
                                                                                                      alt="bachchan-amitabh-image.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-edit" data-toggle="tooltip" title="Download">
                                                                                      <i className="fa-solid fa-cloud-arrow-down" />
                                                                                  </a>
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID15" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID15" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan videoIconBg">
                                                                                          <i className="fa-regular fa-file-video" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="file_video.html">Video.mp4</a>
                                                                                  <div className="SwiftCloudTableTags">
                                                                                      <ul>
                                                                                          <li><a href="#" data-tagname="Video">Video</a></li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <div className="SwiftCloudSharedWithUsers">
                                                                                      <ul>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Stephanie Harris">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/susan.jpg"
                                                                                                      alt="susan.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-edit" data-toggle="tooltip" title="Download">
                                                                                      <i className="fa-solid fa-cloud-arrow-down" />
                                                                                  </a>
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID16" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID16" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan audioIconBg">
                                                                                          <i className="fa-regular fa-file-audio" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="file_audio.html">Audio.mp3</a>
                                                                                  <div className="SwiftCloudTableTags">
                                                                                      <ul>
                                                                                          <li><a href="#" data-tagname="Audio">Audio</a></li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <div className="SwiftCloudSharedWithUsers">
                                                                                      <ul>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Rover V.">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/roger.jpg"
                                                                                                      alt="roger.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-edit" data-toggle="tooltip" title="Download">
                                                                                      <i className="fa-solid fa-cloud-arrow-down" />
                                                                                  </a>
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                  <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID17" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID17" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan pdfIconBg">
                                                                                          <i className="fa-regular fa-file-pdf" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="file_pdf.html">XYZ.pdf</a>
                                                                                  <div className="SwiftCloudSharedWithUsers">
                                                                                      <ul>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="SwiftCloud">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/CloudFlag_SolidBG_Color2760a8_margin.gif"
                                                                                                      alt="CloudFlag_SolidBG_Color2760a8_margin.gif"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Rover V.">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/roger.jpg"
                                                                                                      alt="roger.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                          <li>
                                                                                              <a href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS" data-toggle="tooltip" title="Amitabh Bachchan">
                                                                                                  <StaticImage
                                                                                                      src="./../../images/bachchan-amitabh-image.jpg"
                                                                                                      alt="bachchan-amitabh-image.jpg"
                                                                                                  />
                                                                                              </a>
                                                                                          </li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-edit" data-toggle="tooltip" title="Download">
                                                                                      <i className="fa-solid fa-cloud-arrow-down" />
                                                                                  </a>
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID18" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID18" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan">
                                                                                          <StaticImage
                                                                                              src="./../../images/pedro.jpg"
                                                                                              alt="pedro.jpg"
                                                                                          />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="file_image.html">Image.jpg</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-edit" data-toggle="tooltip" title="Download">
                                                                                      <i className="fa-solid fa-cloud-arrow-down" />
                                                                                  </a>
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID3" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID3" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan btn-secondary">
                                                                                          <i className="bi bi-file-earmark-medical-fill" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">eDoc</a>
                                                                                  <div className="SwiftCloudTableTags">
                                                                                      <ul>
                                                                                          <li><a href="#" data-tagname="Tag1">Tag1</a></li>
                                                                                          <li><a href="#" data-tagname="Tag2">Tag2</a></li>
                                                                                      </ul>
                                                                                  </div>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID4" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID4" className="css-checkbox-label" ></label>
                                                                                      <span className="tableColChkSpan btn-secondary">
                                                                                          <i className="bi bi-table" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Spreadsheet</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact">
                                                                                      <i className="bi bi-person-fill-plus" />
                                                                                  </a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell">
                                                                                  <TimeAgo datetime="2022-05-15" />
                                                                              </td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete">
                                                                                      <i className="bi bi-trash-fill" />
                                                                                  </a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID5" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID5" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan btn-secondary">
                                                                                          <i className="bi bi-table" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Invoice</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID6" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID6" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-list-ul"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Web form</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID7" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID7" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-envelope-fill"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Envelope</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID19" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID19" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-person-fills"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Workroom</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID8" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID8" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-cart4"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Item you sell</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID9" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID9" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-three-dots"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Sequence</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID10" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID10" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-calendar3"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Scheduler</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID11" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID11" className="css-checkbox-label" />
                                                                                      <span className="tableColChkSpan btn-secondary">
                                                                                          <i className="fa fa-clone" />
                                                                                      </span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Template</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID12" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID12" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-telephone-fill"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Phone number</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="text-center tableColChkDel">
                                                                                  <div className="tableColChkDelBox">
                                                                                      <input type="checkbox" name="fileid[]" id="C_ID13" className="css-checkbox" />
                                                                                      <label htmlFor="C_ID13" className="css-checkbox-label"></label>
                                                                                      <span className="tableColChkSpan btn-secondary"><i className="bi bi-box-arrow-in-right"></i></span>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <a href="#">Paywall</a>
                                                                                  <a href="LINK_TO_SOCIALIZER_POPUP" className="btnSocializer" data-toggle="tooltip" title="Share / Attach to Contact"><i className="bi bi-person-fill-plus"></i></a>
                                                                              </td>
                                                                              <td className="d-none d-md-table-cell"><TimeAgo datetime="2022-05-15" /></td>
                                                                              <td className="text-end tableColAction d-none d-lg-table-cell">
                                                                                  <a href="#" className="btn btn-delete" data-toggle="tooltip" title="Delete"><i className="bi bi-trash-fill"></i></a>
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </div>
                                                                  <div className="tab-pane fade" id="small-icon" role="tabpanel" aria-labelledby="small-icon-tab">
                                                                      <ul className="smallIconList">
                                                                          <li className="folderIcon">
                                                                              <a href="#">
                                                                                  <span>
                                                                                      <i className="fa-solid fa-folder" />
                                                                                  </span>
                                                                                  <span className="fileName">Incoming</span>
                                                                              </a>
                                                                          </li>
                                                                          <li className="folderIcon">
                                                                              <a href="#">
                                                                                  <span><i className="fa-solid fa-folder" /></span>
                                                                                  <span className="fileName">Tasks and Projects</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-file-earmark-medical-fill" />
                                                                                  </span>
                                                                                  <span className="fileName">eDoc</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-table" />
                                                                                  </span>
                                                                                  <span className="fileName">Spreadsheet</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-table" />
                                                                                  </span>
                                                                                  <span className="fileName">Invoice</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-list-ul" />
                                                                                  </span>
                                                                                  <span className="fileName">Web form</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-envelope-fill" />
                                                                                  </span>
                                                                                  <span className="fileName">Envelope</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-person-fills" />
                                                                                  </span>
                                                                                  <span className="fileName">Workroom</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-cart4" />
                                                                                  </span>
                                                                                  <span className="fileName">Item you sell</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-three-dots" />
                                                                                  </span>
                                                                                  <span className="fileName">Sequence</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-calendar3" />
                                                                                  </span>
                                                                                  <span className="fileName">Scheduler</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="fa fa-clone" />
                                                                                  </span>
                                                                                  <span className="fileName">Template</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-telephone-fill" />
                                                                                  </span>
                                                                                  <span className="fileName">Phone number</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="btn-secondary fileTypeIcon">
                                                                                      <i className="bi bi-box-arrow-in-right" />
                                                                                  </span>
                                                                                  <span className="fileName">Paywall</span>
                                                                              </a>
                                                                          </li>
                                                                          <li>
                                                                              <a href="#">
                                                                                  <span className="fileTypeIcon contact-detail-doc-with-featured-image" />
                                                                                  <span className="fileName">Doc with featured image</span>
                                                                              </a>
                                                                          </li>
                                                                      </ul>
                                                                  </div>
                                                                  <div className="tab-pane fade" id="large-icon" role="tabpanel" aria-labelledby="large-icon-tab">
                                                                      <ul className="largeIconList">
                                                                      <li className="folderIcon">
                                                                          <a href="#">
                                                                              <span>
                                                                                  <i className="fa-solid fa-folder" />
                                                                              </span>
                                                                              <span className="fileName">Incoming</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="folderIcon">
                                                                          <a href="#">
                                                                              <span>
                                                                                  <i className="fa-solid fa-folder" />
                                                                              </span>
                                                                              <span className="fileName">Tasks and Projects</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-file-earmark-medical-fill" />
                                                                              </span>
                                                                              <span className="fileName">eDoc</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-table" />
                                                                              </span>
                                                                              <span className="fileName">Spreadsheet</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-table" />
                                                                              </span>
                                                                              <span className="fileName">Invoice</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-list-ul" />
                                                                              </span>
                                                                              <span className="fileName">Web form</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-envelope-fill" />
                                                                              </span>
                                                                              <span className="fileName">Envelope</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-person-fills" />
                                                                              </span>
                                                                              <span className="fileName">Workroom</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-cart4" />
                                                                              </span>
                                                                              <span className="fileName">Item you sell</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-three-dots" />
                                                                              </span>
                                                                              <span className="fileName">Sequence</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-calendar3" />
                                                                              </span>
                                                                              <span className="fileName">Scheduler</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="fa fa-clone" />
                                                                              </span>
                                                                              <span className="fileName">Template</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-telephone-fill" />
                                                                              </span>
                                                                              <span className="fileName">Phone number</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary">
                                                                          <a href="#">
                                                                              <span className="fileTypeIcon">
                                                                                  <i className="bi bi-box-arrow-in-right" />
                                                                              </span>
                                                                              <span className="fileName">Paywall</span>
                                                                          </a>
                                                                      </li>
                                                                      <li className="btn-secondary contact-detail-doc-with-featured-image">
                                                                          <a href="#">
                                                                              <span className="fileName">Doc with featured image</span>
                                                                          </a>
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                      <div className="col-lg-12 text-center">
                                          <button
                                              onClick={updateHistoryBlockOpen} type="button" title="History"
                                              className="btn btn-default btn-lg btnOpenChatDock" data-bs-toggle="tooltip"
                                          >
                                              <i className="bi bi-clock-history" />
                                          </button>
                                      </div>
                                  </div>
                              </div>

                              <ContactHistory
                                  contact={contact}
                                  contactName={contactName}
                                  contactEmails={contactEmails}
                                  contactPhones={contactPhones}
                                  contactHistoryNotes={contactHistoryNotes}
                                  setContactHistoryNotes={setContactHistoryNotes}
                              />

                          </div>
                      </div>
                  </div> }
              </div>
          </section>
      </Layout>

  )
}

export default connect(state => {
    return {...state}
})(ContactDetail)
