import * as React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {useEffect, useState} from "react";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import Axios from "axios";
import { getAccessToken } from "../../../services/auth";
import {
    PRIVACY_PUBLIC,
    PRIVACY_PRIVATE,
    CONTACT_META_FIELD_OPTIONS,
    DEFAULT_CONTACT_PHONE_OPTION
} from "../../../services/globalStaticData";
import {connect} from "react-redux";

const AddDetailRow = ({ contact, removeNewDataRow, dataRowIndex, getContactData, dispatch }) => {

    const
        [infoType, setInfoType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
        [infoPrivacy, setInfoPrivacy] = useState(PRIVACY_PUBLIC),
        [infoData, setInfoData] = useState(''),
        [dataRowIcon, setDataRowIcon] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
        [dataRowLabel, setDataRowLabel] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
        [dataRowApiRoute, setDataRowApiRoute] = useState(DEFAULT_CONTACT_PHONE_OPTION.contactApi),
        [dataRowError, setDataRowError] = useState(''),
        [dataRowInputPlaceHolder, setDataRowInputPlaceHolder] = useState(DEFAULT_CONTACT_PHONE_OPTION.placeHolder),
        [removeDataRow, setRemoveDataRow] = useState(false)
    ;

    const saveRowData = () => {
        if(infoData && infoType && dataRowApiRoute && contact.id) {
            notificationProcessing(dispatch);
            setDataRowError('');

            return new Promise(async () => {
                await Axios.post(
                    process.env.GATSBY_BACKEND_API_URL + dataRowApiRoute + contact.id,
                    {
                        type: infoType,
                        permission: infoPrivacy,
                        value: infoData
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken()
                        }
                    }
                ).then(function (response) {
                    if(response.status === 201) {
                        notificationSuccess(dispatch);
                        setRemoveDataRow(true);
                    }
                }).catch(function (error) {
                    let message = 'There is some error please check the data.';
                    if(error.response.status === 400) {
                        if(error.response.data.phone) {
                            message = error.response.data.phone
                        } else if (error.response.data.email) {
                            message = error.response.data.email
                        } else if (error.response.data.url) {
                            message = error.response.data.url
                        } else {
                            message = error.response.data.detail
                        }
                    }
                    setDataRowError(message)
                    notificationFail(dispatch);
                });
            });
        }
    }

    useEffect(() => {
        if(removeDataRow) {
            removeNewDataRow(dataRowIndex)
            getContactData(contact.id)
        }
    })

    return (
        <div className="CD_Info_Row border-bottom p-3 CD_Info_Row_1670502681307">
            <div className="row align-items-center">
                <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className={dataRowIcon} />&nbsp;{ dataRowLabel }
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{maxHeight: '250px', overflow:'auto'}}>

                            {CONTACT_META_FIELD_OPTIONS.map( (fieldOption,index) => {

                                let style = fieldOption.type === "" ? { background: '#f7f7f7' } : {}
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={(e) => {
                                            setInfoType(fieldOption.type)
                                            setDataRowIcon(fieldOption.icon)
                                            setDataRowLabel(fieldOption.label)
                                            setDataRowInputPlaceHolder(fieldOption.placeHolder)
                                            setDataRowApiRoute(fieldOption.contactApi)
                                        }}
                                        disabled={fieldOption.type === ""}
                                        style={style}
                                    >

                                        <i className={fieldOption.icon} />&nbsp; {fieldOption.label}
                                    </Dropdown.Item>
                                )
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                    <span data-bs-toggle="tooltip" aria-label="Private Information" onClick={() => {
                        setInfoPrivacy(
                            infoPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE
                        )
                    }}>
                        <i className={ infoPrivacy === PRIVACY_PRIVATE ? "bi bi-eye-slash-fill privateInfo" : "bi bi-eye-slash-fill"} />
                    </span>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <input
                        type="text"
                        className="form-control CD_input_info"
                        value={infoData}
                        defaultValue={infoData}
                        placeholder={dataRowInputPlaceHolder}
                        onChange={(e) => {
                            setInfoData(e.target.value);
                        }}
                        onMouseLeave={(e) => {
                            setInfoData(e.target.value);
                        }}
                    />
                    { dataRowError && <span className="error-text">
                            {dataRowError}
                        </span>
                    }
                </div>
                <div className="col-sm-1 col-xs-12 position-relative CD_Action">
                    <a className="btnCDSave" data-bs-toggle="tooltip" data-placement="top" aria-label="Delete"
                       onClick={ () => saveRowData() }
                    >
                        <i className="bi bi-save2" />
                    </a>
                    <a className="btnCDDelete" data-bs-toggle="tooltip" data-placement="top" aria-label="Delete"
                       onClick={() => {
                           setRemoveDataRow(true)
                       }}
                    >
                        <i className="bi bi-trash-fill" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default connect(state => {
    return { ...state }
})(AddDetailRow)