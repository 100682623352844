import * as React from "react";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    CONTACT_SOCIAL_LINK_OPTIONS,
    getTypeData,
    PRIVACY_PUBLIC
} from "../../../services/globalStaticData";

const ContactSocialLinks = ({ contactId, contactSocialLinks, setContactSocialLinks, getContactData, dispatch }) => {

    const [updateContact, setUpdateContact] = useState(false);

    const deleteLink = (linkId) => {
        if(linkId) {
            notificationProcessing(dispatch);

            return new Promise(async () => {
                await Axios.delete(
                    `${process.env.GATSBY_BACKEND_API_URL}/contact/social-link/delete/${linkId}` ,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken()
                        }
                    }
                ).then(function (response) {
                    if(response.status === 200) {
                        notificationSuccess(dispatch);
                        setUpdateContact(true)
                    }
                }).catch(function (error) {
                    if(error.response.status === 400) {
                        // setNotesTextAreaError(error.response.data.message)
                    } else {
                        // setNotesTextAreaError(error.response.data.detail)
                    }

                    notificationFail(dispatch);
                });
            });
        }
    }

    useEffect( () => {
        if(updateContact) {
            setUpdateContact(false);
            getContactData(contactId)
        }
    })
    return (
        <React.Fragment>

                { contactSocialLinks !== null && contactSocialLinks.length > 0 && contactSocialLinks.map( (linkData) => {
                    let linkTypeData = getTypeData(CONTACT_SOCIAL_LINK_OPTIONS, linkData.type);
                    let iconClasses = linkTypeData ? linkData.permission === PRIVACY_PUBLIC ? linkTypeData.icon : linkTypeData.icon + " privateInfo" : "";

                    return (
                        <React.Fragment key={`contact_detail_social_link_${linkData.id}`}>
                            {
                                linkTypeData && linkData.url &&
                                <div className="CD_Info_Row border-bottom p-3">
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{linkData.type}</div>
                                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                                            <span data-bs-toggle="tooltip" data-placement="top" title="Private Information">
                                                <i className={iconClasses} />
                                            </span>
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <a href="#" className=" btnOpenChatDock">{linkData.url}</a>&nbsp;
                                            <div className="showOnHover d-inline-block">
                                                <a href="#" className="btn btn-secondary btnQuickAction btnOpenChatDock" data-bs-toggle="tooltip" data-placement="top" title="SMS">
                                                    <i className="bi bi-box-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                                            <a className="btnCDEdit" data-bs-toggle="tooltip" data-placement="top" title="Edit">
                                                <i className="bi bi-pencil-fill" />
                                            </a>
                                            <a className="btnCDDelete" data-bs-toggle="tooltip" data-placement="top" title="Delete"
                                               onClick={() => deleteLink(linkData.id)}
                                            >
                                                <i className="bi bi-trash-fill" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    )})
                }

        </React.Fragment>
    )
}

export default connect(state => {
    return { ...state }
})(ContactSocialLinks)