import * as React from "react";
import {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import {connect} from "react-redux";
import {historyBlockHide} from "../../../redux/reducers/contact/historyNotesDisplay";
import Moment from "react-moment";


const ContactHistory = ({
    contact, contactName, contactEmails, contactPhones,
    contactHistoryNotes, setContactHistoryNotes,
    dispatch, contactHistoryNoteDisplayClasses
}) => {

    const
        [historyType, setHistoryType] = useState('private_note'),
        [chatBlockHeight, setChatBlockHeight] = useState(window.innerHeight),
        [chatBlockTop, setChatBlockTop] = useState(document.querySelector('.header-container').clientHeight),
        [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
        [updateHistoryBlock, setUpdateHistoryBlock] = useState(false),
        [notesTextArea, setNotesTextArea] = useState(''),
        [notesTextAreaError, setNotesTextAreaError] = useState('')
    ;

    const addNotes = () => {
        if(notesTextArea && contact.id) {
            notificationProcessing(dispatch);
            setNotesTextAreaError('');

            return new Promise(async () => {
                await Axios.post(
                    `${process.env.GATSBY_BACKEND_API_URL}/history-note/add`,
                    {
                        contactId: contact.id,
                        content: notesTextArea,
                        mode: "public",
                        type: "note",
                        status: true
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken()
                        }
                    }
                ).then(function (response) {
                    if(response.status === 201) {
                        setNotesTextArea('')
                        setUpdateHistoryBlock(true)
                        notificationSuccess(dispatch);
                    }
                }).catch(function (error) {

                    if(error.response.status === 400) {
                        setNotesTextAreaError(error.response.data.message)
                    } else {
                        setNotesTextAreaError(error.response.data.detail)
                    }
                    notificationFail(dispatch);
                });
            });
        }
    }

    const getHistoryNotes = () => {
        return new Promise(async () => {
            await Axios.get(
                `${process.env.GATSBY_BACKEND_API_URL}/contact/history-note/${contact.id}`,{
                    headers: {
                        'Authorization': 'Bearer ' + getAccessToken()
                    }
                }
            ).then(function (response) {
                setContactHistoryNotes(response.data)
                return true;

            }).catch(function (error) {
                if(error.response.status === 400) {
                    setNotesTextAreaError(error.response.data.message)
                } else {
                    setNotesTextAreaError(error.response.data.detail)
                }
            });
        });
    }

    const updateHistoryBlockClose = () => {
        dispatch(historyBlockHide())
    }

    const updateHistoryBlockHeight = () => {
        setChatBlockHeight(window.innerHeight - document.querySelector('.header-container').clientHeight);
        setChatBlockTop(document.querySelector('.header-container').clientHeight);
        setHistoryBlockHeight(
            window.innerHeight
            - document.querySelector('.header-container').clientHeight
            - document.querySelector('.ChatDockHeader').clientHeight
            - document.querySelector('.ChatDockFooterContainer').clientHeight
        );
    }

    useEffect( () => {
        if(updateHistoryBlock) {
            setUpdateHistoryBlock(false);
            if(contact.id) {
                getHistoryNotes();
            }
        }

        let updateHistoryBlockHeightInterval = setInterval(() => {
            updateHistoryBlockHeight();
            clearInterval(updateHistoryBlockHeightInterval);
        }, 500)

    })
    return (
        <div className="col-lg-6 col-md-12">
            <div className={contactHistoryNoteDisplayClasses} style={{ top: chatBlockTop, height: chatBlockHeight }}>
                <div className="ChatDockHeader">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto">
                            <Form.Select
                                className="ChatDockInputOpt btn btn-secondary"
                                data-width="200"
                                defaultValue={historyType}
                            >

                                <option value="history" data-icon="fa-history">History</option>
                                <option value="private_note" data-icon="fa-sticky-note">Add Note re: {contactName}</option>
                                {
                                    contactEmails != null && contactEmails.length > 0 &&
                                    contactEmails.map((emailData, emailDataIndex) => {
                                        return (
                                            <option value="Email" data-icon="fa-envelope" key={`history_options_${emailDataIndex}`}>
                                                Email to {emailData.email}
                                            </option>
                                        )
                                    })
                                }

                                {
                                    contactPhones != null && contactPhones.length > 0 &&
                                    contactPhones.map((phoneData, phoneDataIndex) => {
                                        return (
                                            <React.Fragment key={`history_options_${phoneDataIndex}`}>
                                                <option value="SMS" data-icon="fa-mobile">SMS to {phoneData.phone}</option>
                                                <option value="Phone" data-icon="fa-phone">Call {phoneData.phone}</option>
                                            </React.Fragment>
                                        )
                                    })
                                }


                                <option value="Map" data-icon="fa-location-pin">Directions to 10945 Hortense Street</option>
                                <option value="Videocall" data-icon="fa-video">Videocall</option>
                            </Form.Select>
                        </div>
                        <div className="col-auto">
                            <div className="ChatDockOpt ChatDockEmailOpt ChatDockEmailToOpt d-none">
                                <div className="row gx-1 align-items-center">
                                    <div className="col-auto">via</div>
                                    <div className="col-auto">
                                        <select name="ChatDockEmailUsing" id="ChatDockEmailUsing" className="form-select">
                                            {
                                                contactEmails != null && contactEmails.length > 0 &&
                                                contactEmails.map((emailData, emailDataIndex) => {
                                                    return (
                                                        <option key={`history_options_${emailDataIndex}`}>
                                                            {emailData.email}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        <select className="ChatDockEmailCcBcc" data-width="56">
                                            <option defaultValue="" data-icon="fa-ellipsis-h">Select</option>
                                            <option value="Cc" data-icon="fa-envelope">Add Cc</option>
                                            <option value="Bcc" data-icon="fa-envelope">Add Bcc</option>
                                        </select>
                                    </div>
                                    <div className="col-auto ChatDockEmailCcBccInput d-none">
                                        <input type="email" className="form-control" placeholder="Enter email address" />
                                    </div>
                                    <div className="col-auto">
                                        <select name="ChatDockEmailAs" className="ChatDockEmailAs" data-width="60">
                                            <option defaultValue="Default" data-icon="fa-envelope">Default</option>
                                            <option value="SecureMessage" data-icon="fa-lock">Secure Message (Login Required)</option>
                                            <option value="HTML" data-icon="fa-code">Branded Newsletter</option>
                                            <option value="PlainText" data-icon="fa-bars">Plain Text</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a onClick={updateHistoryBlockClose} className="btnCloseChatDock" data-bs-toggle="tooltip" data-placement="left" data-animation="false" title="Close">
                        <i className="bi bi-x-octagon-fill" />
                    </a>
                </div>
                <div className="ChatDockHistoryContainer" style={{ height: historyBlockHeight, overflow: "auto" }}>
                    <ul className="chat">
                        {contactHistoryNotes !== null && contactHistoryNotes.length > 0 &&
                            contactHistoryNotes.map((historyData, historyIndex) => {
                                let chatClass = historyData.messageSent ? 'self' : 'other';
                                let imagePath = historyData.messageSent ? 'devin.jpg' : 'bachchan-amitabh-image.jpg';
                                if((contactHistoryNotes.length -1 ) === historyIndex) {
                                    document.querySelector('.ChatDockHistoryContainer').scrollTo(0, document.querySelector('.ChatDockHistoryContainer').scrollHeight)
                                }
                                return (
                                    <li className={chatClass} key={`history_note_${historyIndex}`}>
                                        <div className="chat-msg">
                                            <div className="avatar">
                                                <a href="#" className="quickcontact_userSelect d-block" data-bs-toggle="tooltip" data-placement="bottom" data-animation="false" title="Amitabh Bachchan">
                                                    <img src={process.env.GATSBY_WRANGLER_URL + 'StaticImages/' + imagePath } alt=""/>
                                                </a>
                                            </div>
                                            <div className="msg">
                                                <p>
                                                    <strong>
                                                        {historyData.title}
                                                    </strong>
                                                </p>
                                                <p>
                                                    {historyData.content}
                                                </p>
                                                <span className="msg-by-time">
                                                    {historyData.mode} Note by&nbsp; { contactName } at&nbsp;
                                                    <Moment format="hh:mm a on dddd, MMM DD, YYYY">
                                                        {historyData.updatedAt}
                                                    </Moment>
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                )
                            })
                        }
                    </ul>
                </div>
                <div className="ChatDockFooterContainer">
                    <div className="ChatDockOpt ChatDockPrivateNoteOpt">
                        <div className="mb-2">
                            <Form>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Add Note"
                                    value={notesTextArea}
                                    defaultValue={notesTextArea}
                                    onChange={(event) => {
                                        setNotesTextArea(event.target.value)
                                    }}
                                    onMouseLeave={(event) => {
                                        setNotesTextArea(event.target.value)
                                    }}
                                />
                                { notesTextAreaError && <div className="error-text">{notesTextAreaError}</div> }
                            </Form>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                                <a href="#Global_URLInjector" data-bs-toggle="modal" data-bs-toggle="tooltip" title="Add URL">
                                    <i className="bi bi-link-45deg" />
                                </a>
                                <a href="#" data-bs-toggle="tooltip" title="Insert Image/Video">
                                    <i className="bi bi-camera-fill" />
                                </a>
                                <a href="#" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                                    <i className="bi bi-paperclip" />
                                </a>
                                <a href="#" data-bs-toggle="tooltip" title="Inject Emoji">
                                    <i className="bi bi-emoji-smile-fill" />
                                </a>
                                <input type="file" accept="image/*" className="chatNoteImgFile contact-detail-input-file" />
                            </div>
                            <div className="col-lg-6 text-end">
                                <button type="button" className="btn btn-primary" onClick={addNotes}>
                                    <i className="bi bi-check-lg" /> Add Note
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="ChatDockOpt ChatDockEmailOpt d-none">
                        <div className="mb-2">
                            <input type="text" className="form-control" placeholder="Subject" />
                        </div>
                        <div className="mb-2">
                            <textarea className="form-control" placeholder="Message" rows="5"></textarea>
                        </div>
                        <div className="mb-2 text-end">
                            <div className="row">
                                <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                                    <a href="#Global_URLInjector" data-bs-toggle="modal" data-bs-toggle="tooltip" title="Add URL">
                                        <i className="bi bi-link-45deg" />
                                    </a>
                                    <a href="#" data-bs-toggle="tooltip" title="Insert Image/Video">
                                        <i className="bi bi-camera-fill" />
                                    </a>
                                    <a href="#" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                                        <i className="bi bi-paperclip" />
                                    </a>
                                    <a href="#" data-bs-toggle="tooltip" title="Inject Emoji">
                                        <i className="bi bi-emoji-smile-fill" />
                                    </a>
                                    <input type="file" accept="image/*" className="chatNoteImgFile contact-input-file" />
                                </div>
                                <div className="col-lg-6 text-end">
                                    <button type="button" className="btn btn-primary">
                                        <i className="bi bi-send-fill" /> Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ChatDockOpt ChatDockSMSOpt d-none">
                        <div className="mb-2">
                            <textarea className="form-control" id="smsMessagebox" placeholder="Add Note"></textarea>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                                <a href="#Global_URLInjector" data-bs-toggle="modal" data-bs-toggle="tooltip" title="Add URL">
                                    <i className="bi bi-link-45deg" />
                                </a>
                                <a href="#" data-bs-toggle="tooltip" title="Insert Image/Video">
                                    <i className="bi bi-camera-fill" />
                                </a>
                                <a href="#" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                                    <i className="bi bi-paperclip" />
                                </a>
                                <a href="#" data-bs-toggle="tooltip" title="Inject Emoji">
                                    <i className="bi bi-emoji-smile-fill" />
                                </a>
                                <input type="file" accept="image/*" className="chatNoteImgFile contact-input-file" />
                                <span className="ChatDockSMSCounter">140 remaining</span>
                            </div>
                            <div className="col-lg-6 text-end">
                                <button type="button" className="btn btn-primary">
                                    <i className="bi bi-send-fill" /> Send
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="ChatDockOpt ChatDockPhoneOpt d-none">
                        <div className="text-center py-3">
                            <button className="btn btn-primary btnChatDockCallNow">
                                <i className="bi bi-telephone-fill" /> Call Now
                            </button>
                            <div className="phoneCallingContainer d-none">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12">
                                        <h3 className="mb-2">Amitabh Bachchan</h3>
                                        <div className="timerStart mb-3">00:00:00</div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit">
                                            <button type="button" className="btn btn-primary">
                                                <i className="bi bi-volume-up-fill" /> Leave Voicemail
                                            </button>
                                            <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">General VM</a></li>
                                                <li><a className="dropdown-item" href="#">Fast Cash VM</a></li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-danger btnChatDockCallEnd mt-1">
                                            <i className="bi bi-x-octagon-fill" /> Hang Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ChatDockOpt ChatDockVideocallOpt d-none">
                        <div className="mb-2 text-center">
                            <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit">
                                <button type="button" className="btn btn-primary">
                                    <i className="bi bi-play-circle" /> Start New Video Meeting
                                </button>
                                <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item btnVideoChatOptMeetNow" href="#">
                                            <i className="fa fa-copy" /> Meet Now &amp; Share Meeting URL
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item btnVideoChatOptCreateMeeting" href="#">
                                            <i className="fa fa-clone" /> Create Meeting Link for Future
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item btnVideoChatOptScheduleMeeting" href="#">
                                            <i className="fa fa-clone" /> Schedule a Meeting
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(state => {

    let contactHistoryNoteDisplayClasses = 'SCChatDockNonSocialAssetOnly d-none d-lg-block';

    if (state.contactHistoryNoteDisplayReducer) {
        contactHistoryNoteDisplayClasses = state.contactHistoryNoteDisplayReducer.blockDisplayClasses
    }

    return {
        contactHistoryNoteDisplayClasses: contactHistoryNoteDisplayClasses,
    }
})(ContactHistory)