import * as React from "react";
import {useEffect, useState} from "react";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {connect} from "react-redux";

const ContactName = ({ contactId, contactName, setContactName, tempContactName, setTempContactName, dispatch}) => {

    const [updateName, setUpdateName] = useState(false);
    const [contactNameError, setContactNameError] = useState('');

    const saveContactName = () => {
        if(contactId) {
            if(tempContactName) {
                notificationProcessing(dispatch);

                return new Promise(async () => {
                    await Axios.post(
                        `${process.env.GATSBY_BACKEND_API_URL}/contact/update-name/${contactId}`,
                        {
                            contactName: tempContactName
                        },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getAccessToken()
                            }
                        }
                    ).then(function (response) {
                        setContactName(tempContactName)
                        notificationSuccess(dispatch);
                    }).catch(function (error) {
                        notificationFail(dispatch);
                    });
                });
            } else {
                setContactNameError('Please add Valid Contact Name');
                notificationFail(dispatch);
            }
        }
    }

    useEffect( () => {
        if(updateName) {
            setUpdateName(false);
            if(contactName !== tempContactName) {
                saveContactName()
            }
        }
    })

    return (
        <React.Fragment>
            <input type="text" className="form-control" placeholder="Contact Name"
                   value={tempContactName}
                   defaultValue={tempContactName}
                   onChange={(e) => setTempContactName(e.target.value)}
                   onBlur={(e) => setUpdateName(true)}
                   onKeyDown={(e) => {
                       if (e.keyCode === 13) {
                           setUpdateName(true)
                       }
                   }}
            />
            {contactNameError && <div className="error-text"> {contactNameError} </div>}
        </React.Fragment>
    )
}

export default connect(state => {
    return { ...state }
})(ContactName);